<template>
    <div class="st-main paper-main">
        <div class="st-container">
            <div class="main-l">
                <user-avatar />
                <div class="ai-review paper-score">
                    <span class="score-title">
                        <i class="fa fa-pie-chart"></i>
                        <span>考试得分</span>
                    </span>
                    <template v-if="lastExam != '' && lastExam != null">
                        <div class="score-content">
                            <span class="score-content__title">{{ lastExam.shijuan.title }}</span>
                            <el-progress type="circle" :percentage="lastExam.score" :stroke-width="scoreWidth">
                            </el-progress>
                            <div class="score-content__footer">
                                <span></span>
                                <span>上次考试成绩</span>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <th>题目类型</th>
                                <th>得分</th>
                            </tr>
                            <tr v-for="(item, index) in lastExam.stage_score" :key="index">
                                <td>{{ item.name }}</td>
                                <td>{{ item.score }}分</td>
                            </tr>
                        </table>
                    </template>
                </div>
            </div>
            <!-- 首页 -->
            <div class="main-r paper-area">
                <div class="paper-area__left">
                    <div class="paper-area__title">
                        <span>待测试卷区</span>
                    </div>
                    <div class="paper-area__content">
                        <p class="paper-area__content--title">试卷</p>
                        <ul class="paper-area__content--list">
                            <li v-for="(item, index) in shijuanList" @click="toTestCenter(item)" :key="index">
                                <div>
                                    <span>{{ ++index }}、</span>
                                    <span :title="item.title">{{ item.title }}</span>
                                </div>
                                <el-button type="primary" size="mini" v-if="item.kaoshi_count > 0"
                                    @click="toReTestCenter(item)">再测一遍</el-button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="paper-area__right">
                    <div class="paper-area__title">
                        <span>&nbsp;</span>
                    </div>
                    <div class="paper-area__content">
                        <p class="paper-area__content--title">今日任务清单</p>
                        <div class="paper-area__content--box">
                            <span style="display:none">
                                <i class="fa fa-volume-up"></i>
                                <a href="#">外研版小学四年级英语期中检测测试题</a>
                            </span>
                            <div class="paper-area__content--echarts">
                                <div ref="chart" class="chart" style="width: 500px; height: 420px;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :fullscreen="true" :visible.sync="resultDialogVisible" v-if="resultDialogVisible"
            custom-class="kaoshiResultDialog">
            <paper-result :result="result" @close="resultClose" />
        </el-dialog>
        <el-dialog :fullscreen="true" :visible.sync="analysisDialogVisible" v-if="analysisDialogVisible">
            <paper-analysis @close="analysisClose" :kaoshi-info="kaoshiDetailData.kaoshiInfo"
                :shijuan-info="kaoshiDetailData.info" :exam-list="kaoshiDetailData.info.shijuan_mold"
                :score="kaoshiDetailData.kaoshiInfo.score" />
        </el-dialog>
    </div>
</template>


<script>
import { mapState } from "vuex";
import LightMusic from "@/views/study/light_music";

import paperResult from "@/views/papers/paper_result"
import paperAnalysis from "@/views/papers/paper_analysis"
import userAvatar from "@/views/study/UserAvatar";
import spacial from "@/components/spacial"

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        LightMusic,
        // eslint-disable-next-line vue/no-unused-components
        paperResult,
        paperAnalysis,
        userAvatar,
        spacial
    },
    data() {
        return {

            specialList: [],
            specialTotal: 0,
            specialHas: 10,
            specialIndex: 0,

            textShow: false,
            avatarVisible: false,
            eyes: false,
            score: 60,
            scoreWidth: 10,
            shijuanList: [],
            search: '',
            border: true,
            getIndex: '',
            lastExam: null,


            result: {},
            kaoshiDetailData: {},
            resultDialogVisible: false,
            analysisDialogVisible: false,
        };
    },
    created() {
        this.jsq();

        console.log(this.user);


        // setInterval(()=>{
        //     this.getList();
        // }, 30000)

        this.getShijuanList();
        this.getUserInfo();
        this.getLastExam();
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        jinDu: function () {
            // `this` 指向 vm 实例
            var jd = 0;
            if (this.user.level != undefined) {
                var baoshi = this.user.baoshi || 0;
                var upper_exp = this.user.level.end_inter;
                var lower_exp = this.user.level.start_inter;
                var all = upper_exp - lower_exp;
                var j = baoshi - lower_exp;
                //console.log("upper_exp：" + upper_exp + "lower_exp：" + lower_exp);
                if (all <= 0) {
                    jd = 0;
                } else if (j > all) {
                    jd = 100;
                } else {
                    jd = Math.round((j / all) * 100);
                }
            }
            /*if(upper_exp>0){
                        jd = Math.round((lower_exp / upper_exp) * 100);
                      }
                      if(jd<0){
                        jd = 0;
                      }else if(jd>100){
                        jd = 100;
                      }*/
            return jd;
        },
        percent: function () {
            let percents;
            percents = this.specialHas / this.specialTotal * 100;
            if (percents > 100) {
                percents = 100
            } else if (percents < 0) {
                percents = 0
            } else {
                return percents
            }
        }
    },
    mounted() {
        //let scores = document.querySelector('.el-progress__text');
        //scores.innerHTML = scores.innerHTML.replace('%', '');
        this.getEchartsData();
        // this.getEchartsDatas()
    },
    methods: {
        changeEyes() {
            this.eyes = !this.eyes;
        },
        getShijuanList() {
            let data = {};
            data.token = this.token;
            //console.log(data);
            this.axios.post("/api/exam/shijuan_list", data).then((res) => {
                //console.log(res);
                if (res.data.status_code == 200) {
                    let data = res.data.data;
                    this.shijuanList = data.list;
                    //console.log(data.list);
                } else {
                    this.$message(res.data.msg);
                }
            }).catch((err) => { });
        },
        getLastExam() {
            let data = {};
            data.token = this.token;
            //console.log(data);
            this.axios.post("/api/exam/last_exam", data).then((res) => {
                //console.log(res);
                if (res.data.status_code == 200) {
                    let data = res.data.data;
                    this.lastExam = data;
                } else {
                    this.$message(res.data.msg);
                }
            }).catch((err) => { });
        },
        toTestCenter(item) {
            if (item.kaoshi_count > 0) {
                this.getKaoshiDetail(item.id);
                //this.$message('您已考过该试卷，不能重复考试');
            } else {
                this.$router.push({
                    name: "papers_test_papers",
                    query: {
                        id: item.uuid,
                    },
                });
            }

        },
        toReTestCenter(item) {
            //再测一遍
            this.$router.push({
                name: "papers_test_papers",
                query: {
                    id: item.uuid,
                },
            });
        },
        navEvent(index) {
            //this.$store.commit('setPage', index);
        },
        schoolEvent(type) {
            this.type = type;
            this.page = 1;
            this.getList();

        },
        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getList();

            //console.log(`当前页: ${val}`);
        },
        handleClick(tab, event) {
            //console.log(tab, event);
        },
        format(percentage) {
            return `${percentage}`;
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {
                });
        },
        logout() {
            //console.log("退出登陆");
            this.axios
                .post("/api/auth/logout", { token: this.token, get_type: this.type, })
                .then((res) => {
                })
                .catch((err) => {
                    this.loading = false;
                });
            this.$store.commit("logout");
            this.$router.push({ name: "login" });
        },
        jsq() {
            //点击开始建 开始计数
            let that = this;
            var uid = this.user.id;
            //console.log("uid:" + uid);
            var s = window.sessionStorage.getItem("jqx_count" + uid);
            var count = 0;
            if (s) {
                //console.log("sessionStorage:" + s);
                count = s;
            }
            var timer = null; //timer变量记录定时器setInterval的返回值
            timer = setInterval(function () {
                count++;
                window.sessionStorage.setItem("jqx_count" + uid, count);
                //console.log(count);
                // 需要改变页面上时分秒的值
                //console.log($("id_S"));
                var times_str = "";
                times_str += that.showNum(parseInt(count / 60 / 60)) + ":";
                times_str += that.showNum(parseInt(count / 60) % 60) + ":";
                times_str += that.showNum(count % 60);
                //console.log(times_str);
                that.jsq_times = times_str;
            }, 1000);
        },
        //封装一个处理单位数字的函数
        showNum(num) {
            if (num < 10) {
                return "0" + num;
            }
            return num;
        },
        getEchartsData() {
            const chart = this.$refs.chart
            if (chart) {
                const myChart = this.$echarts.init(chart)
                const option = {
                    legend: {
                        top: 'bottom'
                    },
                    color: [
                        '#66c79a',
                        '#f2c454',
                        '#de82d1',
                        '#5aa8f6'
                    ],
                    series: [
                        {
                            type: 'pie',
                            data: [
                                {
                                    value: 200,
                                    name: '听力'
                                },
                                {
                                    value: 250,
                                    name: '语言知识运用'
                                },
                                {
                                    value: 3000,
                                    name: '阅读理解'
                                },
                                {
                                    value: 1380,
                                    name: '书面表达'
                                }
                            ],
                            roseType: 'area',
                            radius: ['20%', '80%'],
                            itemStyle: {
                                borderRadius: 6,
                                borderColor: '#fff',
                                borderWidth: 2,
                            }
                        }
                    ]
                }
                myChart.setOption(option)
                window.addEventListener('resize', function () {
                    myChart.resize()
                })
                this.$on('hook:destroyed', () => {
                    window.removeEventListener("resize", function () {
                        myChart.resize();
                    })
                })
            }
        },
        getEchartsDatas() {
            const chart = this.$refs.chart
            if (chart) {
                const myChart = this.$echarts.init(chart)
                const option = {
                    legend: {
                        top: 'bottom'
                    },
                    color: [
                        '#66c79a',
                        '#f2c454',
                    ],
                    series: [
                        {
                            type: 'pie',
                            data: [
                                {
                                    value: 400,
                                    name: '听力'
                                },
                                {
                                    value: 1548,
                                    name: '语言知识运用'
                                },
                            ],
                            roseType: 'area',
                            radius: ['20%', '80%'],
                            itemStyle: {
                                borderRadius: 6,
                                borderColor: '#fff',
                                borderWidth: 2
                            }
                        }
                    ]
                }
                myChart.setOption(option)
                window.addEventListener('resize', function () {
                    myChart.resize()
                })
                this.$on('hook:destroyed', () => {
                    window.removeEventListener("resize", function () {
                        myChart.resize();
                    })
                })
            }
        },
        // 排行榜 点击改变行内样式
        handle(row) {
            //console.log(row);
            //console.log(row.id);
            this.getIndex = row.id - 1;
        },
        isChecked({ rowIndex }) {
            //console.log(rowIndex)
            if ((this.getIndex) === rowIndex) {
                return {
                    "color": '#278df2'
                }
            }
        },
        resultClose() {
            this.resultDialogVisible = false;
            this.analysisDialogVisible = true;
        },
        analysisClose() {
            console.log("analysisClose");
            this.analysisDialogVisible = false;
        },
        getKaoshiDetail(id) {
            let data = {};
            data.id = id;
            data.token = this.token;
            this.axios.post("/api/exam/detail", data).then((res) => {
                //console.log(res);
                if (res.data.status_code == 200) {
                    this.kaoshiDetailData = res.data.data;
                    //this.shijuanList = data.list;
                    //console.log(data);
                    this.resultClose();

                } else {
                    this.$message(res.data.msg);
                }
            })
                .catch((err) => {
                    //this.loading = false;
                });
        },
        showResult() {
            let result = this.result;
            result.yongshi_time = this.kaoshiDetailData.kaoshiInfo.yongshi_time;
            result.yongshi = this.kaoshiDetailData.kaoshiInfo.yongshi;
            result.shijuanInfo = this.kaoshiDetailData.info;
            result.kaoshiInfo = this.kaoshiDetailData.kaoshiSetInfo;
            result.score = this.kaoshiDetailData.kaoshiInfo.score;
            result.stageScoreArr = this.kaoshiDetailData.kaoshiInfo.stage_score;
            this.resultDialogVisible = true;
        },
        getUserInfo() {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
            };
            //console.log(params);
            this.axios
                .post("/api/user/get_user_info", params)
                .then((res) => {
                    let data = res.data;
                    //console.log("get_user_info");
                    //console.log(data, "用户信息");
                    if (data.status_code == 200) {
                        //console.log(data.data);
                        this.$store.commit("setUser", data.data);
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.paper-area {
    &__content {
        &--list {
            overflow-y: auto;
            height: 510px;
            &::-webkit-scrollbar {
                width: 8px;
                height: 120px;
                border-radius: 4px;
            }
            &::-webkit-scrollbar-track {
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #fefefe;
            }
            li {
                display: flex;
                justify-content: space-between;
                padding: 4px 15px 4px;
                text-align: left;
                cursor: pointer;
                transition: all .5s;

                &:hover {
                    background-color: #f3f5f7;
                }
                .el-button {
                    padding: 4px;
                }
                & > div {
                    display: flex;
                    align-items: center;

                    span {
                        &:last-child {
                            max-width: 200px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}
</style>
